import React from 'react'
import { useInView } from 'react-intersection-observer'
import { graphql } from "gatsby"

import SEO from '../../components/seo'
import Footer from '../../components/Footer'
import Img from "gatsby-image"
import Intro from "../../animations/Intro"
import { Link } from 'gatsby'
import './style.scss'
import FormDetail from '../../components/Content/ACF/Custom/FormDetail'

const QRPage = ({ data }) => {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const [io2, io2InView] = useInView({ triggerOnce: true })
  const [io3, io3InView] = useInView({ triggerOnce: true })

  return (
    <>
      <main id="qr-code-landing">
        <SEO title={"QR"} description="Discover a vibrant community where diverse dreams flourish. At MRU, we celebrate individuality and empower students to chase their unique aspirations. Our mission is more than just words—it’s a commitment to providing top-tier undergraduate and graduate education in a nurturing environment" />

        <section ref={io} className={`x1 t`}>
          <div className='bg-radial' />

          <Intro visible={ioInView} in={{ fade: 750 }} delayIn={50} mounted={true} stay={true}>
            <div className='heading flex-12'>
              <div>
                <a href="https://portal.mru.edu/Applicant/InfoRequest.aspx" target={'_blank'} className='button bg-white border-white text-blue bold italic font-industry'>Enroll Today</a >
              </div>
              <div>
                <div style={{ paddingBottom: "28%", minWidth: "16em" }} className='aspect-ratio'>
                  <Img fluid={data.file.childImageSharp.fluid}
                    imgStyle={{ objectFit: 'contain' }}
                    objectPosition='50% 50%'
                    alt='Logo light'
                  />
                </div>
              </div>
              <div>
                <a href="#qr-form" className='button bold italic font-industry'>Request Info<span className="btn-arrow"></span></a >
              </div>
            </div>
          </Intro>

          <Intro visible={ioInView} in={{ fade: 750 }} delayIn={50} mounted={true} stay={true} className="span-7 span-12-tablet">
            <div className='attention flex'>
              <h1 className='w-full italic font-industry'>
                <span className='thin'>NOW THAT WE <br />HAVE YOUR<br /></span>ATTENTION…
              </h1>
              <h4 className='yellow italic border font-industry'>We welcome you to MRU</h4>
              <p className='w-full text-left'>Discover a vibrant community where diverse dreams flourish. At MRU, we celebrate individuality and empower students to chase their unique aspirations. Our mission is more than just words—it’s a commitment to providing top-tier undergraduate and graduate education in a nurturing environment. We believe in equipping every student with the tools they need to succeed: a positive mindset, honed skills, and clear goals. <span className='bold italic'>Join us and unlock your potential at MRU!</span></p>
              <h6 className='padd-top italic last-h font-industry fs-125'>HERE are the programs we offer…</h6>
            </div>
          </Intro>

        </section>

        <section ref={io2} className={`font-industry italic padd-top bold`}>

          <Intro visible={io2InView} in={{ fade: 500 }} delayIn={100} mounted={true} stay={true} className="span-7 span-12-tablet">
            <div style={{ zIndex: "100" }} className='right-bg padd'>
              <Intro visible={io2InView} in={{ fadeLeft: 500 }} delayIn={250} mounted={true} stay={true} className="">
                <div className='single-school'>
                  <div className='content padd padd-top-off'>
                    <h6 className='yellow fs-85 font-industry margin-off group-title'>SCHOOLS OF BUSINESS & EDUCATION</h6>
                    <div className="links-container">
                      <Link to="/archives/academic/master-of-education-in-educational-administration" className='button'>MASTER OF EDUCATION <br /> IN EDUCATIONAL ADMINISTRATION<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/master-of-business-administration" className='button'>MASTER OF BUSINESS ADMINISTRATION<span className="btn-arrow"></span></Link >
                    </div>
                  </div>
                </div>
              </Intro>
              <Intro visible={io2InView} in={{ fadeLeft: 500 }} delayIn={375} mounted={true} stay={true} className="">
                <div className='single-school'>
                  <div className='content padd padd-top-off'>
                    <h6 className='yellow fs-85 font-industry margin-off group-title'>GENERAL STUDIES</h6>
                    <div className="links-container">
                      <Link to="/archives/academic/bachelor-of-science-in-applied-behavior-analysis-bs-aba" className='button'>BACHELOR OF SCIENCE IN <br /> APPLIED BEHAVIOR ANALYSIS (BS-ABA)<span className="btn-arrow"></span></Link >
                    </div>
                  </div>
                </div>
              </Intro>
              <Intro visible={io2InView} in={{ fadeLeft: 500 }} delayIn={500} mounted={true} stay={true} className="">
                <div className='single-school'>
                  <div className='content padd padd-top-off'>
                    <h6 className='yellow fs-85 font-industry margin-off group-title'>SCHOOL OF NURSING</h6>
                    <div className="links-container">
                      <Link to="/archives/academic/doctor-of-nursing-practice-program" className='button'>DOCTOR OF NURSING PRACTICE<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/adult-gerontology-acute-care-post-master-certification" className='button'>ADULT GERONTOLOGY ACUTE CARE <br /> POST-MASTER CERTIFICATION<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/family-nurse-practitioner-post-master-certificate-fnp-pmc" className='button'>FAMILY NURSE PRACTITIONER <br /> POST-MASTER CERTIFICATE (FNP-PMC)<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/psychiatric-mental-health-post-master-certificate" className='button'>PSYCHIATRIC MENTAL HEALTH POST-MASTER CERTIFICATE<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/master-of-science-in-nursing-direct-entry" className='button'>MASTER OF SCIENCE IN NURSING DIRECT ENTRY<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/family-nurse-practitioner2" className='button'>MASTER OF SCIENCE IN NURSING<br /> FAMILY NURSE PRACTITIONER<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/bachelor-of-science-degree-in-nursing-bsn-2" className='button'>BACHELOR OF SCIENCE DEGREE IN NURSING (BSN)<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/bachelor-of-science-in-nursing-rn-to-bsn" className='button'>BACHELOR OF SCIENCE IN NURSING (RN TO BSN)<span className="btn-arrow"></span></Link >
                      <Link to="/archives/academic/associate-of-science-degree-in-nursing2" className='button'>ASSOCIATE OF SCIENCE DEGREE IN NURSING<span className="btn-arrow"></span></Link >
                    </div>
                  </div>
                </div>
              </Intro>
            </div>
          </Intro>
        </section>

        <section ref={io3}>
          <Intro visible={io3InView} in={{ fade: 750 }} delayIn={200} mounted={true} stay={true} className="span-7 span-12-tablet">
            <div className='buttons-section'>
              <div className='flex'>
                <a href="https://sisportal-100291.campusnexus.cloud/CMCPortal/Applicant/ApplyOnline_CreateAccount.aspx" target={'_blank'} className='button bg-white border-white text-yellow bold italic font-industry'>Apply Now</a>
              </div>
              <div className='flex'>
                <a href="#qr-form" className='button border-white text-yellow bold italic font-industry'>Request Info</a>
              </div>
              <div className='flex'>
                <Link to="/student-life" className='button  border-white text-yellow bold italic font-industry'>Campus Life</Link >
              </div>
            </div>
          </Intro>
        </section>

        <section className='c0 inview padd-top-2 padd-bottom'>
          <div id='qr-form' className="anchor"></div>
          <div id="block-custom-request-information" className="block block-custom span-6 span-12-mobile">
            <FormDetail fields={"Request information"} />
          </div>
        </section>

        <style jsx global>{`
        #master-hamburger-positioner {
          display: none
        }
			`}</style>

        <Footer />
      </main>

    </>

  )
}

export default QRPage


export const query = graphql`
  query {
    file(relativePath: { eq: "logo-white.png" }) {
      childImageSharp {
        fluid (
          maxWidth: 1280,
          quality: 80,
          srcSetBreakpoints: [960,1280,1920,2560]
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`